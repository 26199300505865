import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SystemNav',
    meta:{activeIndex:'1'},
    component:()=>import("@/views/SystemNav.vue"),
    redirect: { name: "Index" },
    children:[
      {
        path: 'index',
        name: 'Index',
        meta:{activeIndex:'1'},
        component:()=>import("@/views/Index/Index.vue"),
      },
      {
        path: 'news',
        name: 'News',
        meta:{activeIndex:'1'},
        component:()=>import("@/views/Index/components/News.vue"),
      },
      {
        path: 'newslist',
        name: 'Newslist',
        meta:{activeIndex:'1'},
        component:()=>import("@/views/Index/components/Newslist.vue"),
      },
      {
        path: 'courses',
        name: 'Courses',
        meta:{activeIndex:'2'},
        component:()=>import("@/views/Course/Course.vue"),
      },
    ]
  },
  
  {
    path: '/home',
    name: 'Home',
    component: ()=>import("@/views/Home/Home.vue"),
    redirect: { name: "Course" },
   
    children: [
      {
        path: 'fee',
        name: 'Fee',
        meta:{activeIndex:'1'},
        component:()=>import("@/views/Home/components/Fee.vue"),
      },
      {
        path: 'course',
        name: 'Course',
        meta:{activeIndex:'2'},
        component:()=>import("@/views/Home/components/Course.vue"),
      },
      {
        path: 'result',
        name: 'Result',
        meta:{activeIndex:'3'},
        component:()=>import("@/views/Home/components/Result.vue"),
      },
      {
        path: 'book',
        name: 'Book',
        meta:{activeIndex:'4'},
        component:()=>import("@/views/Home/components/Book.vue"),
      },
    ]
  },
  {
    path: '/classroom',
    name: 'Classroom',
    meta:{activeIndex:'2'},
    component:()=>import("@/views/Classroom/Classroom.vue"),
  },
  {
    path: '/LOGIN',
    name: 'Login',
    component:()=>import("@/views/Login.vue"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//路由导航守卫
// router.beforeEach((to,from,next)=>{
//   if(to.name!="Login"){
//     const state=sessionStorage.getItem('user_info');
//     if(state){
//       next()
//     }else{
//       router.replace({name:"Login"})
//     }
//   }else{
//     next()
//   }
// })
export default router
