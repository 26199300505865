<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name:'App',
  watch:{
      $route:{
        handler(newV){
          this.$store.commit("changeActiveIndex",newV.meta.activeIndex)
        },
        immediate:true
      }
    }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
*{
  margin: 0;
  padding: 0;
}
</style>
