//完成项目中网络的请求配置
const apis = {
    
    login(meta){
        return{
            url:`/login/user`,
            method:'post',
            data:meta
        }
    },
    getclassroom(meta){
        return{
            url:`/classroom?classname=${meta.classname}`,

        }
    },
    get_courses(){
        return{
            url:`/courses`,

        }
    },
    get_news(meta){
        return{
            url:`/news/${meta.id}`,

        }
    },
    get_allNews(){
        return{
            url:`/news`,
        }
    },
    // deletetiku(meta){
    //     return{
    //         url:`/tiku/delete/${meta.id}`,
    //         method: 'delete'
    //     }
    // },
    // search(meta){
    //     return{
    //         url:`/tiku/search?search=${meta.search}&page=${meta.page}&pageSize=${meta.pageSize}`
    //     }
    // },
    // getannouncement(){
    //     return{
    //         url:`/announcement`
    //     }
    // },
    // updateGetannouncement(meta){
    //     return{
    //         url:`/announcement/update/${meta.id}`,
    //         method:'put',
    //         data:{
    //             text:meta.text
    //         }
    //     }
    // },
    // get_users(meta){
    //     return{
    //         url:`/users?page=${meta.page}&pageSize=${meta.pageSize}`
    //     }
    // },
    // delete_users(meta){
    //     return{
    //         url:`/users/delete/${meta.id}`,
    //         method: 'delete'
    //     }
    // },
    // search_users(meta){
    //     return{
    //         url:`users/search?search_name=${meta.search_name}&search_phone=${meta.search_phone}&search_classify_two=${meta.search_classify_two}&status=${meta.status}&page=${meta.page}&pageSize=${meta.pageSize}`
    //     }
    // },
    // importUsers(meta){
    //     return{
    //         url:`users/importUsers`,
    //         method:'post',
    //         data:meta
    //     }
    // },
    // exportUsers(meta){
    //     return{
    //         url:'users/exportUsers',
    //         data:meta
    //     }
    // },
    getbanner() {
        return {
            url: `/banner`,
        }
    },
}

export default apis